<script lang="ts">
    import { onMount } from 'svelte';
    import { decryptBase64, renderAvifToCanvas } from '@/utility';
    import type { Attachment } from '@/interfaces/types';

    export let attachment: Attachment;
    export let type: 'single' | 'gallery';

    let canvas: HTMLCanvasElement;
    let divBackground: HTMLDivElement;
    let errorMessage: string = '';

    function isBase64(str: string): boolean {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    }

    async function processImage(canvas: HTMLCanvasElement, attachmentKey: string, path: string) {
        try {
            const response = await fetch(path);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();

            const [key, salt] = attachmentKey.split('::');
            const decryptedData = await decryptBase64(data.ct, data.iv, key);

            await renderAvifToCanvas(decryptedData, canvas);

            if (type === 'gallery') {
                const dataURL = canvas.toDataURL();
                if (divBackground) {
                    divBackground.style.backgroundImage = `url(${dataURL})`;
                    divBackground.style.backgroundSize = 'cover';
                    divBackground.style.backgroundPosition = 'center';
                }
            }
        } catch (error) {
            console.error('Error in processImage:', error);
            console.error('Error details:', {
                name: error.name,
                message: error.message,
                stack: error.stack,
            });
            throw error;
        }
    }

    function handleImageProcessing() {
        if (canvas) {
            errorMessage = '';
            processImage(canvas, attachment.key, attachment.path).catch((error) => {
                console.error(`Failed to process image for canvas ID: ${canvas.id}`, error);
                errorMessage = `Failed to process image: ${error.message}`;
            });
        } else {
            console.error('Canvas element not found');
            errorMessage = 'Canvas element not found';
        }
    }

    onMount(() => {
        if (canvas) {
            handleImageProcessing();
        } else {
            setTimeout(handleImageProcessing, 0);
        }
    });
</script>

{#if type === 'single'}
    <canvas
        bind:this="{canvas}"
        id="canvas_{attachment.id}"
        data-key="{attachment.key}"
        data-aspect-ratio="{attachment.aspect_ratio || ''}"
        data-path="{attachment.path}"
        draggable="false"
        class="img-fluid rounded-0 w-100"
    ></canvas>
{:else if type === 'gallery'}
    <div class="post-media-image" bind:this="{divBackground}" id="divBackground_{attachment.id}"></div>
    <canvas
        bind:this="{canvas}"
        id="canvas_{attachment.id}"
        data-key="{attachment.key}"
        data-path="{attachment.path}"
        style="display: none;"
    ></canvas>
{/if}

{#if errorMessage}
    <div class="error-message">{errorMessage}</div>
{/if}
